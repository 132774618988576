<template>
  <v-card min-width="780" max-width="780">
    <div class="d-flex align-center">
      <v-card-title style="font-size: 1.45rem !important" class="pa-1">
        {{ hueco.lugar }}
      </v-card-title>
      <span class="align-center pb-0" style="font-size: 1.3rem !important">
        {{ hueco.horario }}
      </span>
      <v-spacer />
      <v-btn fab x-small color="info" class="me-5" @click="imprimir">
        <v-icon>mdi-printer-outline</v-icon>
      </v-btn>
      <v-btn fab x-small color="error" @click="wds">
        <v-icon>mdi-printer-outline</v-icon>
      </v-btn>
      <v-spacer />
      <v-chip class="d-flex mr-2" label :color="hueco.colorTipoEvento" outlined>
        <v-icon small left>{{ hueco.iconTipoEvento }}</v-icon>
        {{ hueco.tipo }} ({{ hueco.nEventos }})
      </v-chip>
    </div>
    <v-divider />
    <v-row no-gutters>
      <v-col class="d-flex align-center">
        <v-chip class="v-chip--active">
          <v-icon left>mdi-human-male</v-icon>
          <span style="font-size: 1.2rem">{{ hueco.nAdultos }}</span>
        </v-chip>
        <v-chip class="v-chip--active">
          <v-icon left>mdi-human-child</v-icon>
          <span style="font-size: 1.2rem">{{ hueco.nNinos }}</span>
        </v-chip>
        <v-icon small>mdi-slash-forward</v-icon>
        <v-text-field
          filled
          dense
          hide-details
          type="number"
          style="max-width: 90px"
          v-model="hueco.dist"
          class="v-superdense-input"
        />
        <span class="ml-1" style="font-size: 1.2rem"
          >={{ personalNecesario }}</span
        >
      </v-col>

      <v-col
        class="text-center d-flex justify-center align-center my-2"
        cols="5"
      >
        <span class="mr-2 pa-1">Faltan</span>
        <div class="d-flex justify-center">
          <h1>{{ personalFaltante }}</h1>
          <v-icon v-if="personalFaltante > 0" right small color="warning"
            >mdi-alert</v-icon
          >
          <v-icon v-if="personalFaltante == 0" right small color="success"
            >mdi-check</v-icon
          >
        </div>
      </v-col>

      <v-col class="mt-n3" cols="12">
        <v-tabs eager dense grow v-model="tab_details">
          <v-tab style="padding: 4px" :value="0" dense>CITA</v-tab>
          <v-tab style="padding: 4px" :value="1" dense>APROX</v-tab>
          <v-tab style="padding: 4px" :value="2" dense>R.EVENTO</v-tab>
          <v-tab style="padding: 4px" :value="3" dense>RESPONSABLES</v-tab>
          <v-tab style="padding: 4px" :value="4" dense>APERITIVO</v-tab>
          <v-tab style="padding: 4px" :value="5" dense>SALÓN</v-tab>
          <v-tab style="padding: 4px" :value="6" dense>FF</v-tab>
          <v-tab style="padding: 4px" :value="7" dense>NOTAS</v-tab>
          <v-tab style="padding: 4px" :value="8" dense>WDS</v-tab>
        </v-tabs>
        <v-tabs-items
          style="font-size: 0.9rem"
          class="py-1"
          v-model="tab_details"
        >
          <v-tab-item :value="0">
            <AuxInput class="mx-2 no-gutters" title="DÍA">
              {{ date }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="LUGAR DEL EVENTO">
              {{ hueco.lugar }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="TIPO EVENTO">
              <v-text-field
                @input="change('tipoEventoCustom')"
                v-model="auxModel.details['tipoEventoCustom']"
                filled
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>

            <v-row
              no-gutters
              v-for="row in citaEquipo"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8" class="d-flex">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="1">
            <v-row
              no-gutters
              v-for="row in bAprox"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8" class="d-flex">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="2">
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">INFORMACIÓN</h1><v-spacer></v-spacer><v-btn icon fab
                @click.stop="activeInfo = !activeInfo"><v-icon>{{ activeInfo ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon></v-btn>
            </v-row>
            <template v-if="activeInfo">
              <AuxInput class="mx-2 no-gutters" title="DÍA">
                {{ date }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="LUGAR DEL EVENTO">
                {{ hueco.lugar }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="TIPO EVENTO">
                <v-text-field
                @input="change('tipoEventoCustom')"
                  v-model="auxModel.details['tipoEventoCustom']"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="ADULTOS">
                {{ hueco.nAdultos }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="NIÑOS">
                {{ hueco.nNinos }}
              </AuxInput>
              <AuxInput class="mx-2 no-gutters" title="PERSONAS TOTALES">
                {{ (hueco.nAdultos || 0) + (hueco.nNinos || 0) }}
              </AuxInput>
            </template>

            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">TIMING Y HORARIOS</h1><v-spacer></v-spacer><v-btn icon fab
                @click.stop="activeTiming = !activeTiming"><v-icon>{{ activeTiming ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon></v-btn>
            </v-row>
            <template v-if="activeTiming">
              <v-row
                no-gutters
                v-for="row in resumenEvento"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </template>
            
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">UNIFORMES</h1><v-spacer></v-spacer><v-btn icon fab
                @click.stop="activeUniforme = !activeUniforme"><v-icon>{{ activeUniforme ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon></v-btn>
            </v-row>
            <template v-if="activeUniforme">
              <v-row
                no-gutters
                v-for="row in uniforme"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </template>
          </v-tab-item>
          <v-tab-item :value="3">
            <v-row
              no-gutters
              v-for="row in equipoEvento"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="4">
            <v-row
              no-gutters
              v-for="row in repartoAperitivo"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="5">
            <h1 class="primary--text ps-7">MENÚ</h1>
            <v-row
              no-gutters
              v-for="row in menuSalon1"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider class="my-2 mx-1" style="border: 2px solid var(--v-secondary-base)"></v-divider>
            <v-row
              no-gutters
              v-for="row in menuSalon2"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider class="my-2 mx-1" style="border: 2px solid var(--v-secondary-base)"></v-divider>
            <v-row
              no-gutters
              v-for="row in menuSalon3"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider class="my-2 mx-1" style="border: 2px solid var(--v-secondary-base)"></v-divider>
            <v-row
              no-gutters
              v-for="row in menuSalon4"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider class="my-2 mx-1" style="border: 2px solid var(--v-secondary-base)"></v-divider>
            <v-divider class="my-2 mx-1" style="border: 5px solid var(--v-secondary-base)"></v-divider>
            <table>
              <thead>
                <tr>
                  <th>RANGO</th>
                  <th>PAREJAS</th>
                  <th>Nº PAX</th>
                  <th>INTOL.</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in repartoSalon"
                >
                  <td style="min-width:70px">{{ row }}</td>
                  <td>
                    <v-text-field
                      @input="change(row)"
                      style="min-width:300px"
                      v-model="auxModel.details[row]"
                      filled
                      dense
                      hide-details
                      class="v-superdense-input"
                    />
                  </td>
                  <td>
                    <v-text-field
                      @input="change(row+'N')"
                      v-model="auxModel.details[(row+'N')]"
                      filled
                      dense
                      hide-details
                      class="v-superdense-input"
                    />
                  </td>
                  <td>
                    <v-text-field
                      @input="change(row+'I')"
                      v-model="auxModel.details[(row+'I')]"
                      filled
                      dense
                      hide-details
                      class="v-superdense-input"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </v-tab-item>
          <v-tab-item :value="6">
            <AuxInput class="mx-2 no-gutters" title="BARRA LIBRE"></AuxInput>
            <v-row
              no-gutters
              v-for="row in ff1"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <AuxInput class="mx-2 no-gutters" title="RECENA"></AuxInput>
            <v-row
              no-gutters
              v-for="row in ff2"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <AuxInput class="mx-2 no-gutters" title="CIERRE"></AuxInput>
            <v-row
              no-gutters
              v-for="row in ff3"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="7">
            <v-row
              no-gutters
              v-for="row in notas"
              :title="row"
              :key="row"
              class="ma-1 mx-2"
            >
              <v-col cols="12" sm="4">
                <h5 v-text="row" />
              </v-col>
              <v-col cols="12" sm="8">
                <v-textarea
                  @input="change(row)"
                  v-model="auxModel.details[row]"
                  filled
                  dense
                  hide-details
                  class="v-superdense-input"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="8">
            <h1 class="primary--text ms-2">DATOS</h1>
            <AuxInput class="mx-2 no-gutters" title="ADULTOS">
              {{ hueco.nAdultos }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="NIÑOS">
              {{ hueco.nNinos }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="TIPO EVENTO">
              <v-text-field
                @input="change(row)"
                v-model="auxModel.details['tipoEventoCustom']"
                filled
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="TECNICOS">
              <v-text-field
                @input="change(row)"
                v-model="auxModel.details['tecnicos']"
                filled
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="HORARIO">
              {{ hueco.horario }}
            </AuxInput>
            <AuxInput class="mx-2 no-gutters" title="NOTA PERSONAL PAREJA">
              <v-textarea
                @input="change('notaPareja')"
                rows="3"
                v-model="auxModel.details['notaPareja']"
                filled
                dense
                hide-details
                class="v-superdense-input"
              />
            </AuxInput>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">ENLACE</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeEnlace = !activeEnlace"><v-icon>{{ activeEnlace ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeEnlace ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace2"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">APERITIVO</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeAptvo = !activeAptvo"><v-icon>{{ activeAptvo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeAptvo ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace3"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace4"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace5"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace6"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">BANQUETE</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeBanquete = !activeBanquete"><v-icon>{{ activeBanquete ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeBanquete ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace7"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace8"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">FIN DE FIESTA</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeFf = !activeFf"><v-icon>{{ activeFf ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeFf ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace9"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace10"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace11"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="row in enlace12"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">RECOGIDA DETALLES</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeRecogidaDetalles = !activeRecogidaDetalles"><v-icon>{{ activeRecogidaDetalles ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeRecogidaDetalles ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace13"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row class="align-center pa-0 ma-0">
              <h1 class="primary--text ms-2">NOTAS GENERALES</h1><v-spacer></v-spacer><v-btn icon fab @click.stop="activeNotas = !activeNotas"><v-icon>{{ activeNotas ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-row>
            <div style="overflow: hidden !important" :class="activeNotas ? 'active' : 'activent'">
              <v-row
                no-gutters
                v-for="row in enlace14"
                :title="row"
                :key="row"
                class="ma-1 mx-2"
              >
                <v-col cols="12" sm="4">
                  <h5 v-text="row" />
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-textarea
                    @input="change(row)"
                    rows="3"
                    v-model="auxModel.details[row]"
                    filled
                    dense
                    hide-details
                    class="v-superdense-input"
                  />
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <HuecoDrop :all-members="allMembers" v-model="auxModel" :cuentan="cuentan" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {
    HuecoDrop: () => import("./HuecoDrop.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  props: {
    value: Object,
    hueco: Object,
    allMembers: Array,
    date: String,
    cuentan: Array
  },
  data() {
    return {
      keyHelper: 0,
      personalAsignado: 0,
      tab_details: 0,
      details: false,
      activeInfo: false,
      activeTiming: false,
      activeUniforme: false,
      activeEnlace: false,
      activeAptvo: false,
      activeBanquete: false,
      activeFf: false,
      activeRecogidaDetalles: false,
      activeNotas: false,
      citaEquipo: [
        // "Día",
        // "TIPO EVENTO",
        // "LUGAR EVENTO",
        "Hº CAMAREROS Y LUGAR DE CITA",
        "MEDIOS TRANSPORTE",
        "UNIFORME CAMARERO",
      ],
      uniforme: [
        "CAMAREROS EXTRAS (MANDILES Y CAMISETAS MONTAJE)",
        "COCINA (DELANTALES Y CASACAS APERITIVOS)",
        "PLONGE EXTRA (CAMISETAS TRABAJO)",
        "BODEGUEROS EXTRA (CAMISETAS TRABAJO)",
      ],
      resumenEvento: [
        // "Día",
        // "TIPO EVENTO",
        // "LUGAR EVENTO",
        "Hº CAMAREROS Y LUGAR DE CITA",
        "MEDIOS TRANSPORTE Y DURACIÓN",
        // "ADULTOS",
        // "NIÑOS",
        // "PERSONAS TOTALES",
        "HORA ENLACE Y LUGAR",
        "INICIO EVENTO",
        "Hº CIERRE",
      ],
      equipoEvento: [
        "DIRECCIÓN",
        "MAITRE 1",
        "MAITRE 2",
        "COCINA",
        "WDS",
        "CORTE JAMÓN",
        "MTL",
        "APOYO APERITIVO",
        "CIERRE & FF",
        "APOYO FIN FIESTA",
        "NUEVOS",
        "RESP. CIERRE",
        "PLG & TRANSPORTE",
        "BDG & TRANSPORTE",
        "Hº ESPECIAL EQUIPO",
      ],
      bAprox:[
        "DIA MONTAJE CARPA & GMF",
				"TIPO DE BUFFETS",
				"Nº BUFFETS",
				"Nº PLANCHAS (EN BUFFETS)",
      ],
			repartoAperitivo : [
				"BEBIDA EC",
				"WDS - LLEGADA Y DIPTICOS",
        "CAVA BIENVENIDA",
        "BARRA BEBIDA",
        "BEBIDA ESPECIAL AP",
				"BUFFET JAMÓN",
				"BUFFET QUESOS",
        "BUFFET FOIE",
        "BUFFET ARROCES",
        "BUFFET SHOWCOOKING",
        "BUFFET KM.0",
        "BUFFET TAKE AWAY",
        "BUFFET PULPEIRO",
        "BUFFET MARINERO",
        "BUFFET ESPECIAL",
        "PASANDO Y RECOGIENDO",
        "RECOGIENDO",
        "ATENCIÓN NOVIOS y PADRES",
				"NOTAS",
			],
      notas: [
        "ENLACE",
        "APERITIVO",
        "SALÓN",
        "FIN FIESTA",
        "GENERALES / WDS & MOMENTOS EMOTIVOS",
      ],
			repartoSalon : Array(10).fill(0).map( (_, i) => `Rango ${i+1}`) ,
      menuSalon1:[
        "ENTRANTE",
        "PRIMER PLATO",
        "SORBETE",
        "CARNE",
        "POSTRE",
        "NOTA COMIDA",
      ],
      menuSalon2:[
        "VINO BLANCO",
        "VINO TINTO",
        "CAVA",
        "OTRA BEBIDA",
        "NOTA BEBIDA",
      ],
      menuSalon3:[
      "NIÑOS (MENÚ // MTL // NOTAS)",
      ],
      menuSalon4:[
      "CAFÉS Y LICORES",
      ],
      ff1: [
        "LICOR EN MESA",
        "1º TURNO BL",
        "2º TURNO BL Y CIERRE",
        "QUIEN CIERRA",
        "BEBIDA ESPECIAL",
        "Hº CIERRE",
        "POSIB. ALARGAR"
      ],
      ff2:[
        "TIPO RECENA",
        "RECENA ESPECIAL",
        "HORA RECENA"
      ],
      ff3:[
        "RESP. CIERRE",
        "BDG Y PLG CIERRE",
        "NOTAS CIERRE",
      ],
      enlace:[
        "TIPO ENLACE",
        "ESPACIO ",
        "HORA ENLACE",
        "HORA LLEGADA INVITADOS",
        "BEBIDA PREVIA o POSTERIOR",
        "SONIDO (ENCARGADO)",
        "DECORACIÓN E.C.",
        "PERSONA CONTACTO FAMILIA (PARA LLEGADAS)"
      ],
      enlace2:[
        "DETALLES A COLOCAR EN EL ENLACE",
        "OBSERVACIONES y MOMENTOS"
      ],
      enlace3:[
        "HORA INICIO APTVO",
        "UBICACIÓN APERITIVO",
        "UBICACIÓN CAVA BIENVENIDA",
        "ENSEÑAR A PADRES ANTES",
        "REPARTO MINUTA DE BIENVENIDA (HACE WDS)"
      ],
      enlace4:[
        "LLEGADA NOVIOS"
      ],
      enlace5:[
        "GUARDAR COMIDA NOVIOS (CONSULTAR MAITRE y COCINA)",
        "MUSICA APERITIVO (SONIDO o GRUPO)",
        "FOTOS (REPORTAJE PAREJA) Y CONTACTO FOTOGRAFO"
      ],
      enlace6:[
        "FOTOS (FOTOS CON INVITADOS)",
        "DETALLES A COLOCAR EN EL APERITIVO",
        "OBSERVACIONES y MOMENTOS EN EL APTIVO"
      ],
      enlace7:[
        "HORA INICIO BANQUETE",
        "UBICACIÓN BANQUETE",
        "OBSERVACIONES COMIDA y/o BEBIDA",
        "SONIDO"
      ],
      enlace8:[
        "COLOCACIÓN CARTELERÍA Y MESEROS",
        "DETALLES A COLOCAR EN EL SALÓN",
        "TIMMING MOMENTOS EMOTIVOS (SI o NO) - CHEQUEAR DJ",
        "OBSERVACIONES y MOMENTOS EN EL SALÓN (VER MOMENTOS EMOTIVOS, DONDE ESTA EL DETALLE Y QUE ES EL DETALLE)",
        "REGALO/DETALLES INVITADOS",
        "DISCURSO",
        "PROYECCIÓN ",
        "DETALLES AMIGOS"
      ],
      enlace9:[
        "HORA INICIO",
        "HORA FIN",
        "UBICACIÓN ",
      ],
      enlace10:[      
        "APERTURA BAILE (NOTAS)"
      ],
      enlace11:[
        "SONIDO (DJ o GRUPO)"
      ],
      enlace12:[
        "DETALLES A COLOCAR EN EL FIN DE FIESTA (ATREZZO)",
        "OBSERVACIONES y MOMENTOS FIN DE FIESTA"
      ],
      enlace13:[
        "DONDE SE GUARDAN LOS DETALLES",
      ],
      enlace14:[
        "AMENITIES MASFARRE",
        "OTROS DETALLES DE LA BODA"
      ]
    };
  },
  computed: {
    auxModel: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    personalNecesario() {
      return Math.ceil(this.hueco.nAdultos / this.hueco.dist);
    },
    personalFaltante() {
      this.keyHelper++;
      this.$set(
        this.hueco,
        "faltan",
        this.personalNecesario - this.personalAsignado
      );
      return this.personalNecesario - this.personalAsignado;
    },
  },
  watch: {
    "auxModel.plan": {
      handler(v) {
        this.keyHelper++;
        var aux = []
        this.cuentan.forEach(e => {
          if (e.cuenta) aux.push(e.idCategoriaEquipo)
        });
        this.personalAsignado = v.filter((v) => aux.includes(v.idCategoriaEquipo)).reduce((t, a) => t + Number(a.num), 0);
        this.$set(this.hueco, "personalAsignado", this.personalAsignado);
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    change(key){
      if(!this.auxModel.details['detailsCambios']) this.auxModel.details['detailsCambios'] = []
      this.$set(this.auxModel.details,'detailsCambios',[...new Set([...this.auxModel.details['detailsCambios'],key])])
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + this.hueco.lugar + ' ' + this.hueco.horario + ' _ ' + this.hueco.tipo + '(' + this.hueco.nEventos + ') _ ' + (new Date(this.hueco.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.hueco.date).getDate()).slice(-2)) + ' de ' + new Date(this.hueco.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.hueco.date).getFullYear()) +'</title><style>ul {font-size: 14px !important};html, body { height: auto; }</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + this.hueco.lugar + ' ' + this.hueco.horario + '</h3><h3>' + ' ' + this.hueco.tipo + ' (' + this.hueco.nEventos + ')</div></h3><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + (new Date(this.hueco.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.hueco.date).getDate()).slice(-2)) + '/' + new Date(this.hueco.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.hueco.date).getFullYear()) + '</h3></div></div><hr/></div>'
      
      //equipo
      var equipo = v.document.createElement('div')
      equipo.style = "break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; vertical-align:top; border: 1px solid black; padding: 4px"
      equipo.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>EQUIPO</h1>"
      var t = v.document.createElement('table')
      t.style = "border-collapse:collapse"
      t.innerHTML = "<thead><tr><td style='border: 1px solid black'><b>NOMBRE</b></td><td style='border: 1px solid black'><b>CATEG.</b></td><td style='border: 1px solid black'><b>Hº ESP</b></td><td style='border: 1px solid black'><b>COCHE</b></td><td style='border: 1px solid black'><b>CONF</b></td></tr></thead>"
      var tb = v.document.createElement('tbody')
      this.hueco.plan.sort((a, b) => a.nombreCategoria - b.nombreCategoria).forEach(e => {
        tb.innerHTML += "<tr style=background-color:" + e.color + "><td style='white-space: nowrap;border:1px solid black;'>" + e.nombre + ' ' + e.apellidos + "</td><td style='white-space: nowrap;border:1px solid black'>" + e.nombreCategoria + "</td><td style='white-space: nowrap;border:1px solid black;text-align:center;font-weight:bold;'>" + (e.specialSchedule ? 'X' : '') + "</td><td style='white-space: nowrap;border:1px solid black;text-align:center;font-weight:bold;'>" + (e.car ? 'X' : '') + "</td><td style='white-space: nowrap;border:1px solid black;text-align:center;font-weight:bold;'>" + (e.conf ? 'X' : '') + "</td></tr>"
      });
      t.appendChild(tb)
      equipo.appendChild(t)
      wrapper.appendChild(equipo)

      //cita
      var cita = v.document.createElement('div')
      cita.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      cita.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>CITA EQUIPO</h1>"
      cita.innerHTML += "<div><b>DÍA: </b><span style='color: #0086b3'>" + this.date + "<span></div>"
      cita.innerHTML += "<div><b>LUGAR DEL EVENTO: </b><span style='color: #0086b3'>" + this.hueco.lugar + "</span></div>"
      if (this.auxModel.details['tipoEventoCustom']) cita.innerHTML += "<div><b>TIPO EVENTO: </b><span style='color: #0086b3'>" + this.auxModel.details['tipoEventoCustom'] + "</span></div>"

      var c = 0
      this.citaEquipo.forEach(r => {
        if (this.auxModel.details[r]) cita.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>";
      });
      wrapper.appendChild(cita)

      //resumen
      var resumen = v.document.createElement('div')
      resumen.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      resumen.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>RESUMEN</h1>"
      resumen.innerHTML += "<h3 style='margin: 0px;'>INFORMACIÓN</h3>"
     
      resumen.innerHTML += "<div><b>DÍA: </b><span style='color: #0086b3'>" + this.date + "</span></div>"
      resumen.innerHTML += "<div><b>LUGAR DEL EVENTO: </b><span style='color: #0086b3'>" + (this.hueco.lugar.charAt(this.hueco.lugar.length) == '-' ? this.hueco.lugar.slice(0,this.hueco.lugar.length-2) : this.hueco.lugar) + "</span></div>"
      if (this.auxModel.details['tipoEventoCustom']) resumen.innerHTML += "<div><b>TIPO EVENTO: </b><span style='color: #0086b3'>" + this.auxModel.details['tipoEventoCustom'] + "</span></div>"
      resumen.innerHTML += "<div><b>ADULTOS: </b><span style='color: #0086b3'>" + this.hueco.nAdultos + "</span></div>"
      resumen.innerHTML += "<div><b>NIÑOS: </b><span style='color: #0086b3'>" + this.hueco.nNinos + "</span></div>"
      resumen.innerHTML += "<div><b>TOTAL: </b><span style='color: #0086b3'>" + ((this.hueco.nAdultos || 0) + (this.hueco.nNinos || 0)) + "</span></div>"

      resumen.innerHTML += "<h3 style='margin: 0px;border-top:1px solid grey'>TIMING Y HORARIOS</h3>"
      this.resumenEvento.forEach(r => {
        if (this.auxModel.details[r]) resumen.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>"
      });
      resumen.innerHTML += "<h3 style='margin: 0px;border-top:1px solid grey'>UNIFORMES</h3>"
      this.uniforme.forEach(r => {
        if (this.auxModel.details[r]) resumen.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>"
      });
      wrapper.appendChild(resumen)

      //responsables
      var responsables = v.document.createElement('div')
      responsables.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      responsables.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>RESPONSABLES</h1>"
     
      this.equipoEvento.forEach((r,i) => {
        if (this.auxModel.details[r]) {
          c++;
          if (i == this.equipoEvento.length-1) responsables.innerHTML += "<div><i><b>" + r +": </b></i><span style='color: #0086b3'><i><b>" + this.auxModel.details[r] + "</b></i></span></div>";
          else responsables.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>";
        }
      });
      if (c != 0) wrapper.appendChild(responsables);
      else c = 0;

      //aperitivo
      var aperitivo = v.document.createElement('div')
      aperitivo.setAttribute('id', 'aperitivo')
      aperitivo.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      aperitivo.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>APERITIVO</h1>"

      var notasAperitivo = v.document.createElement('ul')
      notasAperitivo.style= "padding-left: 1.2em;margin-top: 2px;margin-bottom: 2px;"
      notasAperitivo.innerHTML = "<li style='color: #0086b3'>OJO INTOLERANCIAS</li>"+
                              "<li style='color: #0086b3'>LLEGAR ESQUINAS Y MESAS RECOGIDAS</li>"+
                              "<li>MÁXIMA EDUCACIÓN</li>"+
                              "<li>PREPARACIÓN BARRA</li>"+
                              "<li>ATENCIÓN NOVIOS Y PADRES</li>"+
                              "<li>MOBILIARIO (BUFFETS MADERA / SILLAS / SOMBRILLAS...)</li>"+
                              "</ul>"
      aperitivo.appendChild(notasAperitivo)
     
      this.repartoAperitivo.forEach(r => {
        if (this.auxModel.details[r]) aperitivo.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>"
      });

      wrapper.appendChild(aperitivo)

      //salon
      var salon = v.document.createElement('div')
      salon.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      salon.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>REPARTO SALON</h1>"
      
      var tablaMenu = v.document.createElement('table')
      tablaMenu.style = "border-collapse: collapse"
      var thead = v.document.createElement('thead')
      thead.innerHTML = "<tr><th colspan='2'><b>MENÚ</b></th></tr>"
      var tbody = v.document.createElement('tbody')
      c = 0
      this.menuSalon1.forEach((r, i) => {
        if (!!this.auxModel.details[r]){
          c++
          var tr = v.document.createElement('tr')
          tr.innerHTML = "<td style='padding: 3px; border:2px solid gray;'>" + r + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[r] ? this.auxModel.details[r] : ' ') + "</td>";
          tbody.appendChild(tr)
        }
      });
      this.menuSalon2.forEach((r, i) => {
        if (!!this.auxModel.details[r]){
          c++
          var tr = v.document.createElement('tr')
          tr.innerHTML = "<td style='padding: 3px; border:2px solid gray;'>" + r + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[r] ? this.auxModel.details[r] : ' ') + "</td>";
          tbody.appendChild(tr)
        }
      });
      this.menuSalon3.forEach((r, i) => {
        if (!!this.auxModel.details[r]){
          c++
          var tr = v.document.createElement('tr')
          tr.innerHTML = "<td style='padding: 3px; border:2px solid gray;'>" + r + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[r] ? this.auxModel.details[r] : ' ') + "</td>";
          tbody.appendChild(tr)
        }
      });
      this.menuSalon4.forEach((r, i) => {
        if (!!this.auxModel.details[r]){
          c++
          var tr = v.document.createElement('tr')
          tr.innerHTML = "<td style='padding: 3px; border:2px solid gray;'>" + r + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[r] ? this.auxModel.details[r] : ' ') + "</td>";
          tbody.appendChild(tr)
        }
      });
      if (c > 0){
        tablaMenu.appendChild(thead)
        tablaMenu.appendChild(tbody)
      }
      c = 0
      var tablaPax = v.document.createElement('table')
      tablaPax.style = "border-collapse: collapse"
      var thead = v.document.createElement('thead')
      thead.innerHTML = '<tr><th style="padding: 3px; border:2px solid gray">RANGO</th><th style="padding: 3px; border:2px solid gray">PAREJAS</th><th style="padding: 3px; border:2px solid gray">Nº PAX</th><th style="padding: 3px; border:2px solid gray">INTOL.</th></tr>'
      var tbody = v.document.createElement('tbody')

      this.repartoSalon.forEach((r, i) => {
        if (!(!this.auxModel.details[r] && !this.auxModel.details[(r + "N")] && !this.auxModel.details[(r + "I")])){
          c++;
          var tr = v.document.createElement('tr')
          tr.innerHTML = "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>Rango " + (i+1) + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[r] ? this.auxModel.details[r] : ' ') + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[(r + "N")] ? this.auxModel.details[(r + "N")] : ' ') + "</td>";
          tr.innerHTML += "<td style='padding: 3px; border:2px solid gray; color: #0086b3'>" + (this.auxModel.details[(r + "I")] ? this.auxModel.details[(r + "I")] : ' ') + "</td>";
          tbody.appendChild(tr)
        }
      });
      if (c != 0){
        tablaPax.appendChild(thead)
        tablaPax.appendChild(tbody)
      } 
      else c = 0;

      var notasSalon = v.document.createElement('ul')
      notasSalon.style= "break-inside:avoid !important;padding-left: 1.2em;margin-top: 2px;margin-bottom: 2px;"
      notasSalon.innerHTML = "<li style='color: #0086b3'>SIRVE AGUA (2) // VINO BLANCO (1) // BAJO PLATO + INTOLERANCIAS (1) // SOLOMILLO (1)</li>"+
                              "<li style='color: #0086b3'>OJO INTOLERANCIAS - LOCALIZAR Y HABLAR CON ELLOS</li>"+
                              "<li style='color: #0086b3'>AGILIDAD Y MÁXIMO SERVICIO (COMIDA Y BEBIDA) &#8594 SIEMPRE UNO EN SALÓN</li>"+
                              "<li>SERVICIO BODEGA Y CAVA</li>"+
                              "<li>MOMENTOS EMOTIVOS</li>"+
                              "<ul style='padding-left: 1.6em;'>"+
                                "<li>OJO DETALLES ENTRE PLATOS</li>"+
                                "<li>ENTRADA</li>"+
                                "<li>TARTA Y OTROS DETALLES NOVIOS</li>"+
                                "<li>DETALLES AMIGOS</li>"+
                              "</ul></li>"+
                              "<li>MÁXIMA EDUCACIÓN</li>"
      salon.appendChild(notasSalon)
      salon.appendChild(tablaMenu)
      salon.innerHTML += "<hr/>"
      salon.innerHTML += '<div style="color: #0086b3"><i>servicio de café y licores ágil (licores de 3 en 3, no mesa completa)</i></div>'
      salon.innerHTML += "<hr/>"
      salon.appendChild(tablaPax)
      wrapper.appendChild(salon)
      c = 0;
      //ff
      var ff = v.document.createElement('div')
      ff.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      ff.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>FIN DE FIESTA</h1>";
      ff.innerHTML += "<div>BARRA LIBRE</div>";
      this.ff1.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          ff.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>";
        }
      });
      if (c == 0) ff.innerHTML += "<div>SIN DATOS</div>";
      else c = 0;
      ff.innerHTML += "<hr/>";
      ff.innerHTML += "<div>RECENA</div>";
      this.ff2.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          ff.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>";
        }
      });
      if (c == 0) ff.innerHTML += "<div>SIN DATOS</div>";
      else c = 0;
      ff.innerHTML += "<hr/>";
      ff.innerHTML += "<div>CIERRE</div>";
      this.ff3.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          ff.innerHTML += "<div><b>" + r +": </b><span style='color: #0086b3'>" + this.auxModel.details[r] + "</span></div>";
        }
      });
      if (c == 0) ff.innerHTML += "<div>SIN DATOS</div>";
      else c = 0;

      wrapper.appendChild(ff)

      //notas
      var notas = v.document.createElement('div')
      notas.style = "vertical-align:top;break-inside:avoid !important;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      notas.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>A TENER EN CUENTA</h1>";
      this.notas.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          notas.innerHTML += "<div><b>" + r +": </b><div style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</div></div>";
        }
      });
      if (c != 0) wrapper.appendChild(notas)
      else c = 0;

      c = 0
      // wds
      var wds = v.document.createElement('div')
      wds.style = "vertical-align:top;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      wds.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>WDS</h1>";
      var tablaWds = v.document.createElement("table")
      tablaWds.style = "border-collapse: collapse"
      tablaWds.innerHTML = "<tbody><tr><td>ADULTOS</td><td>" + (this.hueco.nAdultos || 0) + "</td></tr><tr><td>NIÑOS</td><td>" + (this.hueco.nNinos || 0) + "</td></tr><tr><td>TIPO EVENTO</td><td>" + (this.auxModel.details['tipoEventoCustom'] || '') + "</td></tr><tr><td>TCOS</td><td>" + (this.auxModel.details['tecnicos'] || 0) + "</td></tr><tr><td>HORARIO</td><td>" + (this.hueco.horario || '') + "</td></tr><tr><td>NOTA PERSONAL PAREJA</td><td>" + (this.auxModel.details['notaPareja'] || '') + "</td></tr></tbody>"
      wds.appendChild(tablaWds)
      var tablaEnlace = v.document.createElement("table")
      tablaEnlace.style = "border-collapse: collapse"
      var str = ""
      this.enlace.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace2.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML = "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>ENLACE</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace3.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace4.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace5.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace6.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>APERITIVO</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace7.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace8.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>BANQUETE</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace9.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace10.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace11.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace12.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>FIN DE FIESTA</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      
      str = ""
      this.enlace13.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom:1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>RECOGIDA DETALLES</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      
      str = ""
      this.enlace14.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom:1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>NOTAS GENERALES</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      wds.appendChild(tablaEnlace)
      wrapper.appendChild(wds)

      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
    },
    wds(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>' + this.hueco.lugar + ' ' + this.hueco.horario + ' _ ' + this.hueco.tipo + '(' + this.hueco.nEventos + ') _ ' + (new Date(this.hueco.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.hueco.date).getDate()).slice(-2)) + ' de ' + new Date(this.hueco.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.hueco.date).getFullYear()) +'</title><style>ul {font-size: 14px !important};</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + this.hueco.lugar + ' ' + this.hueco.horario + '</h3><h3>' + ' ' + this.hueco.tipo + ' (' + this.hueco.nEventos + ')</div></h3><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>' + (new Date(this.hueco.date).toLocaleDateString('esp', { weekday : 'short' }).toUpperCase() + ' - ' + (('0'+new Date(this.hueco.date).getDate()).slice(-2)) + '/' + new Date(this.hueco.date).toLocaleDateString('esp', { month : 'long' }).toUpperCase() + ' - ' + new Date(this.hueco.date).getFullYear()) + '</h3></div></div><hr/></div>'
      
      var c = 0
      // wds
      var wds = v.document.createElement('div')
      wds.style = "vertical-align:top;min-width: 220px;font-family: \'Roboto\', sans-serif;margin: 5px;display:block; border: 1px solid black; padding: 4px"
      wds.innerHTML = "<h1 style='margin: 0px;border-bottom:1px solid grey'>WDS</h1>";
      var tablaWds = v.document.createElement("table")
      tablaWds.style = "border-collapse: collapse"
      tablaWds.innerHTML = "<tbody><tr><td>ADULTOS</td><td>" + (this.hueco.nAdultos || 0) + "</td></tr><tr><td>NIÑOS</td><td>" + (this.hueco.nNinos || 0) + "</td></tr><tr><td>TIPO EVENTO</td><td>" + (this.auxModel.details['tipoEventoCustom'] || '') + "</td></tr><tr><td>TCOS</td><td>" + (this.auxModel.details['tecnicos'] || 0) + "</td></tr><tr><td>HORARIO</td><td>" + (this.hueco.horario || '') + "</td></tr><tr><td>NOTA PERSONAL PAREJA</td><td>" + (this.auxModel.details['notaPareja'] || '') + "</td></tr></tbody>"
      wds.appendChild(tablaWds)
      var tablaEnlace = v.document.createElement("table")
      tablaEnlace.style = "border-collapse: collapse"
      var str = ""
      this.enlace.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace2.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML = "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>ENLACE</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace3.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace4.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace5.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace6.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>APERITIVO</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace7.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace8.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>BANQUETE</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      str = ""
      this.enlace9.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace10.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace11.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      this.enlace12.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom: 1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>FIN DE FIESTA</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      
      str = ""
      this.enlace13.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom:1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>RECOGIDA DETALLES</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      
      str = ""
      this.enlace14.forEach(r => {
        if (this.auxModel.details[r]) {
          c++;
          str += "<tr style='border-bottom:1px solid gray'><td><b>" + r +": </b></td><td style='color: #0086b3'>" + this.auxModel.details[r].replace(/\n/gi,'<br>') + "</td></tr>";
        }
      });
      if (c > 0) tablaEnlace.innerHTML += "<thead><tr><td colspan='2' style='text-align:center;font-weight:bold;background-color:lightgray;'>NOTAS GENERALES</td></tr></thead><tbody>" + str + "</tbody>"
      c = 0

      wds.appendChild(tablaEnlace)
      wrapper.appendChild(wds)

      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
    }
  }
};
</script>

<style>
.v-superdense-input .v-input__slot {
  min-height: 25px !important;
}
.v-superdense-input .v-text-field__slot {
  min-height: 25px !important;
}
.active{
  max-height: 10000px !important
}
.activent{
  max-height: 0px !important
}
</style>